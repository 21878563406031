<template>
  <main class="home">
    <div class="hero is-primary">
      <div class="hero-body has-text-centered">
        <h1 class="title is-1">Contact Us</h1>
        <p class="text">
          Get in touch with our team of veteran market segment specialists
          today!
        </p>
      </div>
    </div>
    <br /><br /><br />
    <div class="container">
      <div class="columns is-multiline">
        <div class="column is-12">
          <div class="container">
            <div class="columns is-centered">
              <div class="column is-3 content-wrapper">
                <h1 class="title is-2">Alan Holt</h1>
                <h2
                  class="subtitle"
                  style="font-family: 'Playfair Display', serif"
                >
                  President
                </h2>
                <p>(313) 461-8705</p>
                <p>(313) 397-4093</p>
                <p>
                  <a href="mailto:alan@jonesrholt.com">alan@jones-holt.com</a>
                </p>
                <br />
                <p>Jones-Holt Commercial</p>
                <br />
                <p>12811 Hillview</p>
                <p>Detroit, MI 48227</p>
              </div>
              <div class="column is-5 content-wrapper">
                <form action="https://submit-form.com/HRDrUmo0" class="box">
                  <div class="field">
                    <label for="" class="label">Name</label>
                    <input type="email" class="input is-medium" required />
                  </div>
                  <br />
                  <div class="field">
                    <label for="" class="label">Email</label>
                    <input type="email" class="input is-medium" required />
                  </div>
                  <br />
                  <div class="field">
                    <label for="" class="label">Message</label>
                    <textarea
                      class="textarea is-medium"
                      name="Message"
                      id=""
                    ></textarea>
                  </div>
                  <div class="field">
                    <button
                      type="submit"
                      class="button is-primary is-dark is-fullwidth"
                    >
                      Sent Message
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div v-if="modal" class="modal">
      <div class="modal-background"></div>
      <div class="modal-content">
        <!-- Any other Bulma elements you want -->
      </div>
      <button class="modal-close is-large" aria-label="close"></button>
    </div>
  </main>
</template>

<script>
export default {
  name: "Browse",
  data: () => {
    return {
      propertyData: undefined,
      inventoryCoordinates: undefined,
      modal: false,
      accessToken:
        "pk.eyJ1IjoibXR3aGNyZSIsImEiOiJja3BnMDcwcnIwYjFsMnVvOTMxM2JhdmJjIn0.yKkEMUKcPRyti3ED3mOQUg", // your access token. Needed if you using Mapbox maps
      mapStyle: "mapbox://styles/mtwhcre/ckpotrod00aef17s4x08mzua5", // your map style
      center: [-83.37736141151493, 42.48706224043698],
    };
  },
  computed: {
    routeID: function () {
      return this.$route.params.id;
    },
  },
  methods: {
    async loadPropertyData() {
      this.$prismic.client
        .query(
          this.$prismic.Predicates.at(
            "my.listing.hillview_internal_id",
            this.routeID
          )
        )
        .then((res) => {
          if (res.results_size === 0) this.bail();
          else this.propertyData = res.results[0];
        });
    },
    bail() {
      this.$router.push("/inventory");
    },
  },
};
</script>

<style lang="scss" scoped>
main {
  background: #1a1f36;
  min-height: 100vh;
  font-family: "Lato", sans-serif;
  color: rgba(255, 255, 255, 0.89);
}

.box {
  background: #2a2f45;
  border: 1px solid hsl(39, 40%, 32%);
  font-family: "Lato", sans-serif;
  .label {
    color: rgba(255, 255, 255, 0.89);
  }
  .input,
  .textarea {
    background: #3c4257;
    border-color: #1a1f36;
    color: rgba(255, 255, 255, 0.89);
    &::placeholder {
      color: lightgrey;
    }
  }
}
.navbar-item,
.title {
  color: #d8af64 !important;
  font-family: "Playfair Display";
  letter-spacing: 0.1rem;
  text-shadow: 2px 3px 5px rgba(0, 0, 0, 0.5);
}
hr {
  background: hsl(39, 40%, 32%);
}

.text {
  color: rgba(255, 255, 255, 0.89);
  font-size: 1.3rem;
  line-height: 2rem;
  letter-spacing: 0.05rem;
}
.title {
  color: #d8af64 !important;
  font-family: "Playfair Display";
  letter-spacing: 0.1rem;
  text-shadow: 2px 3px 5px rgba(0, 0, 0, 0.5);
}
.subtitle {
  color: #d8af64;
  margin-top: 1.5rem;
  font-size: 1.4rem;
}

@media only screen and (max-width: 600px) {
  .flex-item {
    width: calc(100vw - 4rem);
  }
  .brand {
    margin: 6rem auto !important;
    text-align: center;
  }
  p {
    font-weight: 600;
  }
  .text {
    font-size: 1.25rem !important;
    line-height: 1.4rem;
  }
}

.content-wrapper {
  padding: 2rem;
}
</style>
